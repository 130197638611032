import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import { UnitProps } from 'components/app/Unit'
import { Block, Carousel, Text, Title } from 'components/shared'
import { Ribbon } from 'components/helpers'
import { Unit, Category, Pizza } from 'components/app'
import { CategoryProps } from 'components/app/Category'

interface ViewProps {
  categories?: CategoryProps[]
  foods?: Food[]
  units?: UnitProps[]
}

const MenuView: React.FC<ViewProps> = ({
  categories = [],
  foods = [],
  units = []
}) => (
  <>
    <Block tag="section" paddingTop={{ xxxs: 'size64', sm: 'size88' }}>
      <Container>
        <Row justify="center">
          <Col xs={12} sm={10} md={8}>
            <Block tag="header" marginBottom="size64">
              <Title
                level="h1"
                appearance="menu"
                secondColor="brown800"
                textAlign="center"
                lineHeight={1}
                uppercase
              >
                <span>Confira nossos sabores:</span> Tradicionais ou Gourmet
              </Title>
            </Block>
          </Col>
        </Row>
      </Container>
      <Block marginBottom="size48">
        <Carousel
          appearance="categories"
          swiper={{
            effect: 'coverflow',
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false
            },
            initialSlide: 0,
            observer: true,
            watchOverflow: true,
            // simulateTouch: false,
            autoplay: {
              delay: 4000
            },
            centeredSlides: true,
            centerInsufficientSlides: true,
            loop: true,
            spaceBetween: 64,
            slidesPerView: 3,
            breakpoints: {
              0: {
                slidesPerView: 2
              },
              1199: {
                slidesPerView: 3
              }
            }
          }}
        >
          {categories.map((category) => (
            <Category key={category.id} {...category} />
          ))}
        </Carousel>
      </Block>
      <Block tag="article" paddingTop="size48">
        <Container>
          <Row>
            {foods.map((food) => (
              <Col key={food.id} xs={6} sm={4} md={3}>
                <Block marginBottom="size48">
                  <Pizza image={food.image} title={food.title} to={food.slug} />
                </Block>
              </Col>
            ))}
          </Row>
        </Container>
      </Block>
    </Block>

    <Block height="size64" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="brown800" slogan="primary" angle={0.4} delay="-12s" />
    </Block>

    <Block tag="section" paddingTop="size64" paddingBottom="size88">
      <Container>
        <Block tag="header" textAlign="center" marginBottom="size48">
          <Text fontSize={14} uppercase>
            Unidades
          </Text>
          <Title size="h3" uppercase>
            Faça-nos uma visita
          </Title>
        </Block>
        <Block tag="article">
          <Carousel
            swiper={{
              initialSlide: 0,
              observer: true,
              watchOverflow: true,
              simulateTouch: false,
              autoplay: {
                delay: 4000
              },
              spaceBetween: 32,
              slidesPerView: 2,
              breakpoints: {
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1
                },
                962: {
                  spaceBetween: 32,
                  slidesPerView: 2
                }
              }
            }}
          >
            {units.map((unit) => (
              <Unit key={unit.id} {...unit} />
            ))}
          </Carousel>
        </Block>
      </Container>
    </Block>
  </>
)

export default MenuView
