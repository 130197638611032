import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Seo } from 'components/helpers'
import { DefaultLayout } from 'components/layouts'

import MenuView from 'views/MenuIndex'

interface IndexPageProps extends PageProps {
  data: {
    allApiUnits: {
      nodes: []
    }
    allApiFoods: {
      nodes: []
    }
    allApiCategories: {
      nodes: []
    }
  }
}

const MenuPage: React.FC<IndexPageProps> = ({ data }) => {
  const { allApiCategories, allApiFoods, allApiUnits } = data

  return (
    <DefaultLayout>
      <Seo title="Pizzas" />
      <MenuView
        categories={allApiCategories.nodes}
        foods={allApiFoods.nodes}
        units={allApiUnits.nodes}
      />
    </DefaultLayout>
  )
}

export const query = graphql`
  query MenuQuery {
    allApiCategories {
      nodes {
        id
        slug
        title
        body
        icon
        util
        image
      }
    }

    allApiFoods(sort: { order: ASC, fields: title }) {
      nodes {
        id
        slug
        title
        image
      }
    }

    allApiUnits(sort: { order: DESC, fields: id }) {
      nodes {
        id
        type
        schedule
        phone
        address
        neighborhood
        image
      }
    }
  }
`

export default MenuPage
